import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";

/* import utils */
import { filterString } from "./../../util/functions";
import { NotifyContext } from "./../../util/notify";

/* import services */
import api from "./../../services/api";
import upload from "./../../services/s3";

import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ReactSelect from "react-select";

/* import components */
import Legend from "./../../components/Legend";
import Search from "./../../components/Search";
import Input from "./../../components/Form/Input";
import Select from "./../../components/Form/Select";
import File from "./../../components/Form/File";

/* import icons */
import IncomeIcon from "./../../assets/icons/income";

/* import styles */
import { Container, Cards, Card, Title, Comment, AddButton, FormModal, FormGroup, FormGroup2, Label } from "./styles";

export default function Regional({ toggleTitles }) {
  toggleTitles("Delegacias", "Gestão das delegacias regionais");

  const { status: colors } = useContext(ThemeContext);
  const { notify } = useContext(NotifyContext);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const site_id = process.env.REACT_APP_SITE_ID;

  const [modalOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const [titleModal, setTitleModal] = useState("Nova delegacia");

  const [id, setId] = useState("");
  const [city, setCity] = useState("");
  const [business_hours, setBusiness_hours] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [address, setAddress] = useState("");
  const [phones, setPhones] = useState("");
  const [emails, setEmails] = useState("");
  const [delegate, setDelegate] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [is_active, setIs_active] = useState(true);
  const [is_online, setIs_online] = useState(false);
  const [start_hour, setStart_hour] = useState("");
  const [end_hour, setEnd_hour] = useState("");
  const [days, setDays] = useState([]);
  const [image_path, setImage_path] = useState("");
  const [images, setImages] = useState([]);

  const available_days = [
    { value: 0, label: "Domingo" },
    { value: 1, label: "Segunda" },
    { value: 2, label: "Terça" },
    { value: 3, label: "Quarta" },
    { value: 4, label: "Quinta" },
    { value: 5, label: "Sexta" },
    { value: 6, label: "Sábado" },
  ];

  const handleFilter = async text => {
    const filtered = initialData.filter(
      item =>
        filterString(item.is_active ?? false, (searchStatus === "inative" ? false : true) ?? "") &&
        (filterString(item.id, text) ||
          filterString(item.city ?? "", text) ||
          filterString(item.business_hours ?? "", text) ||
          filterString(item.address ?? "", text) ||
          filterString(item.phones ?? "", text) ||
          filterString(item.emails ?? "", text) ||
          filterString(item.delegate ?? "", text)),
    );
    setData(filtered);
  };

  const handleClearForm = event => {
    event.preventDefault();

    setTitleModal("Nova delegacia");

    setId("");
    setCity("");
    setBusiness_hours("");
    setAddress("");
    setPhones("");
    setEmails("");
    setDelegate("");
    setLatitude("");
    setLongitude("");
    setWhatsapp("");
    setImage_path("");
    setIs_active(true);
    setImages([]);
  };

  const handleNewData = () => {
    setTitleModal("Nova delegacia");

    setId("");
    setCity("");
    setBusiness_hours("");
    setAddress("");
    setPhones("");
    setEmails("");
    setDelegate("");
    setLatitude("");
    setLongitude("");
    setIs_active(true);
    setImage_path("");
    setImages([]);
    setWhatsapp("");

    setModalOpen(true);
  };

  const handleOpenModalEdit = async (
    p_id,
    p_city,
    p_business_hours,
    p_address,
    p_phones,
    p_emails,
    p_delegate,
    p_latitude,
    p_longitude,
    p_is_active,
    p_image_path,
    p_whatsapp,
    p_is_online,
    p_start_hour,
    p_end_hour,
    p_days,
  ) => {
    setTitleModal("Editar delegacia");

    setId(p_id);
    setCity(p_city);
    setBusiness_hours(p_business_hours);
    setAddress(p_address);
    setPhones(p_phones);
    setEmails(p_emails);
    setWhatsapp(p_whatsapp);
    setDelegate(p_delegate);
    setLatitude(p_latitude);
    setLongitude(p_longitude);
    setIs_active(p_is_active);
    setIs_online(p_is_online);
    setStart_hour(p_start_hour);
    setEnd_hour(p_end_hour);
    setDays(p_days ? available_days.filter(day => p_days.includes(day.value)) : []);
    setImage_path(p_image_path);
    if (p_image_path) {
      setImages([{ file: p_image_path }]);
    }

    setModalOpen(true);
  };

  const handleSave = async event => {
    event.preventDefault();

    let image;

    if (images.length <= 0 && !is_online) {
      notify.show(true, "Uma imagem é obrigatória", "info");
      return;
    }

    if (images && images.length > 0 && !is_online) {
      const imagesUpload = await upload(client_id, site_id, images);

      if (imagesUpload && imagesUpload[0].fileUrl) {
        image = imagesUpload[0].fileUrl;
      } else {
        notify.show(true, "Não foi possivel realizar o upload da imagem! Tente novamente.", "error");
        return;
      }
    }

    notify.show(true, "Salvando...", "info");

    try {
      if (!id) {
        await api.post(`/regionais/`, { image_path: image, city, business_hours, address, phones, emails, delegate, latitude, longitude, is_active, whatsapp, is_online, start_hour, end_hour, days });
        notify.show(true, "Registro adicionado com sucesso", "success");
      } else {
        await api.put(`/regionais/${id}`, {
          image_path: image,
          city,
          business_hours,
          address,
          phones,
          emails,
          delegate,
          latitude,
          longitude,
          is_active,
          whatsapp,
          is_online,
          start_hour,
          end_hour,
          days: days.map(day => day.value),
        });
        notify.show(true, "Registro atualizado com sucesso", "success");
      }

      loadData();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      notify.show(true, "Erro ao salvar registro! Tente novamente", "error");
    }
  };

  async function loadData() {
    try {
      const { data } = await api.get(`/regionais/panel`, {});
      await setInitialData(data);
      await setData(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleFilter(searchValue);
  }, [searchStatus]);

  useEffect(() => {
    loadData();
    document.querySelector("#input").focus();
  }, []);

  return (
    <>
      <Container>
        <Legend
          active={searchStatus}
          onClick={status => {
            setSearchStatus(status);
          }}
          items={[
            {
              key: "status",
              value: "active",
              title: "Filtrar Ativos",
              color: colors["active"],
            },
            {
              key: "status",
              value: "inative",
              title: "Filtrar Inativos",
              color: colors["inative"],
            },
          ]}
        />

        <Cards>
          {data.map(({ id, city, business_hours, address, phones, emails, delegate, latitude, longitude, is_active, image_path, whatsapp, is_online, start_hour, end_hour, days }) => {
            return (
              <Card
                onClick={() => {
                  handleOpenModalEdit(id, city, business_hours, address, phones, emails, delegate, latitude, longitude, is_active, image_path, whatsapp, is_online, start_hour, end_hour, days);
                }}
                status={colors[is_active ? "active" : "inative"]}
                key={id}>
                <Title>{city}</Title>
                <Comment>
                  <br />
                  Endereço: {address}
                  <br />
                  <br />
                  Horário de funcionamento: {is_online ? "Delegacia online" : business_hours}
                  <br />
                  <br />
                  Delegado: {delegate.toString().toUpperCase()}
                  <br />
                  <br />
                  Telefones: {phones}
                  <br />
                  <br />
                  E-mails: {emails}
                </Comment>
              </Card>
            );
          })}
        </Cards>

        <Search
          InitialValue={searchValue}
          onChange={text => {
            setSearchValue(text);
            handleFilter(text);
          }}
        />

        <AddButton onClick={() => handleNewData()}>
          <IncomeIcon /> Nova delegacia
        </AddButton>
      </Container>

      <Dialog
        fullScreen={fullScreen}
        open={modalOpen}
        onClose={event => {
          handleClearForm(event);
          setModalOpen(false);
        }}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{titleModal}</DialogTitle>
        <DialogContent>
          <FormModal onSubmit={handleSave} autoComplete="off">
            <FormGroup>
              <Select
                id="is_online"
                name="is_online"
                label="Delegacia online?"
                tabIndex={111}
                initialValue={is_online}
                options={[
                  { value: true, text: "Sim" },
                  { value: false, text: "Não" },
                ]}
                onChange={text => {
                  setIs_online(text);
                }}
                multiple={false}
              />

              {is_online ? (
                <>
                  <div style={{ display: "flex", width: "100%", gap: "10px", justifyContent: "space-between", flex: 1 }}>
                    <Input
                      style={{ flex: 1 }}
                      id="start_hour"
                      name="start_hour"
                      label="* Horário de início"
                      tabIndex={109}
                      type="time"
                      initialValue={start_hour}
                      required={true}
                      onChange={text => {
                        setStart_hour(text);
                      }}
                    />

                    <Input
                      style={{ flex: 1 }}
                      id="end_hour"
                      name="end_hour"
                      label="* Horário de fim"
                      tabIndex={110}
                      type="time"
                      initialValue={end_hour}
                      required={true}
                      onChange={text => {
                        setEnd_hour(text);
                      }}
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <FormGroup2>
                      <Label>* Dias de funcionamento</Label>
                      <ReactSelect
                        classNamePrefix="react-select"
                        style={{ width: "100%" }}
                        options={available_days}
                        isMulti={true}
                        value={days}
                        defaultValue={days}
                        onChange={option => {
                          setDays(option);
                        }}
                      />
                    </FormGroup2>
                  </div>
                </>
              ) : (
                <Input
                  id="business_hours"
                  name="business_hours"
                  label="* Horário de funcionamento"
                  tabIndex={101}
                  type="text"
                  initialValue={business_hours}
                  onChange={text => {
                    setBusiness_hours(text);
                  }}
                  required={true}
                />
              )}

              <Input
                id="city"
                name="city"
                label="* Cidade/UF"
                tabIndex={102}
                type="text"
                initialValue={city}
                onChange={text => {
                  setCity(text);
                }}
                required={true}
              />

              {!is_online ? (
                <>
                  <Input
                    id="address"
                    name="address"
                    label="* Endereço"
                    tabIndex={103}
                    type="text"
                    initialValue={address}
                    onChange={text => {
                      setAddress(text);
                    }}
                    required={true}
                  />
                </>
              ) : null}

              <Input
                id="delegate"
                name="delegate"
                label="Delegado"
                tabIndex={104}
                type="text"
                initialValue={delegate}
                onChange={text => {
                  setDelegate(text);
                }}
              />

              <Input
                id="phones"
                name="phones"
                label="Telefones (separados por barra / )"
                tabIndex={105}
                type="text"
                initialValue={phones}
                onChange={text => {
                  setPhones(text);
                }}
              />

              <Input
                id="whatsapp"
                name="whatsapp"
                label="WhatsApp"
                tabIndex={106}
                type="text"
                initialValue={whatsapp}
                onChange={text => {
                  setWhatsapp(text);
                }}
              />

              <Input
                id="emails"
                name="emails"
                label="E-mails"
                tabIndex={107}
                type="text"
                initialValue={emails}
                onChange={text => {
                  setEmails(text);
                }}
              />

              {!is_online ? (
                <>
                  <Input
                    id="latitude"
                    name="latitude"
                    label="Latitude (mapa)"
                    tabIndex={108}
                    type="text"
                    initialValue={latitude}
                    onChange={text => {
                      setLatitude(text);
                    }}
                  />

                  <Input
                    id="longitude"
                    name="longitude"
                    label="Longitude (mapa)"
                    tabIndex={109}
                    type="text"
                    initialValue={longitude}
                    onChange={text => {
                      setLongitude(text);
                    }}
                  />
                </>
              ) : null}

              <Select
                id="is_active"
                name="is_active"
                label="Situação"
                tabIndex={110}
                initialValue={is_active}
                options={[
                  { value: true, text: "Ativo" },
                  { value: false, text: "Inativo" },
                ]}
                onChange={text => {
                  setIs_active(text);
                }}
                multiple={false}
              />

              {!is_online ? (
                <>
                  <File
                    id="image_path"
                    initialFile={image_path}
                    onChange={files => {
                      setImages(files);
                    }}
                  />
                </>
              ) : null}
            </FormGroup>

            <button id="btnFormSubmit" style={{ display: "none" }}>
              enviar
            </button>
          </FormModal>
        </DialogContent>

        <DialogActions>
          <Button
            tabIndex={111}
            onClick={event => {
              handleClearForm(event);
              setModalOpen(false);
            }}
            color="secondary">
            Cancelar
          </Button>
          <Button tabIndex={110} color="primary" onClick={() => document.querySelector("#btnFormSubmit").click()}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
